import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export default function StyledLink({ children, to }: any) {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.root}>
      {children}
    </Link>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textDecoration: "none",
    },
  })
);
