export class NotificationService {
  notifcation: any;

  register(notifcation: any) {
    this.notifcation = notifcation;
  }

  show(
    content: any,
    type: "error" | "success" = "error",
    onClose?: any,
    closeText?: string
  ) {
    this.notifcation?.show(content, type, onClose, closeText);
  }

  hide() {
    this.notifcation.hide();
  }

  isShowing() {
    return this.notifcation.show;
  }
}

const notify = new NotificationService();
export default notify;
