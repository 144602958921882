/* eslint-disable react-hooks/exhaustive-deps */
// graphql
import React, { useEffect, useReducer } from "react";
import { DataContext, defaultContext, IDataState } from "./context";

import { IProduct, IProductMetaData } from "./interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import useApi from "./hooks/api";
import { notify } from "./components/Notification";
import { actions } from "./store/productsModeration";
import { ADMIN_LIST } from "./constants";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./Ad";

export default function DataProvider(props: any) {
  const { accounts, instance } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const [state, setState] = useReducer(reducer, defaultContext) as any;

  // api

  const { setproduct } = useApi();

  // redux
  const dispatch = useDispatch();
  const { selected } = useSelector((state: RootState) => ({
    selected: state.productsModeration.selectedProducts,
  }));

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const { accessToken } = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0] as any,
        });
        const user = instance.getAllAccounts()[0];
        try {
          setState({
            type: "SET_AUTH_DATA",
            payload: {
              token: accessToken,
              user: user,
              email: user.username,
              isAdmin: ADMIN_LIST.includes(user?.username?.toLowerCase()),
              isLoggedIn: accessToken ? true : false,
            },
          });
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [isAuthenticated]);

  // set products

  useEffect(() => {
    if (setproduct.state.success) {
      notify.show(`${selected.length || ""} Product(s) updated!`, "success");
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      dispatch(actions.resetSelectedProducts());

      setTimeout(() => {
        notify.hide();
      }, 5000);
    }
  }, [setproduct.state.success]);

  const updateProducts = (product?: any, data?: IProductMetaData) => {
    const mapped = (p: IProduct) => {
      return {
        status: "APPROVED",
        modification_date: new Date(),
        moderator_email: state.user.username,
        id: p._id,
        store_id: p.store_id,
        code: p.code,
        seller_id: p.seller_id,
        reject_reason: null,
        ...data,
      } as IProductMetaData;
    };
    if (product) {
      setproduct.hit(mapped(product));
      return;
    }

    if (selected.length > 0) {
      const approvedItems = selected.map((selectedItems: IProduct) => {
        return mapped(selectedItems);
      });

      setproduct.hit(approvedItems);
      return;
    }
  };

  return (
    <DataContext.Provider
      value={{
        ...state,
        setState: setState,
        tasks: {
          updateProducts: {
            hit: updateProducts,
            complete: setproduct.state.complete,
            pending: setproduct.state.pending,
          },
        },
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}

function reducer(state: IDataState, action: any) {
  switch (action.type) {
    case "SET_AUTH_DATA":
      const { token, user, isLoggedIn, isAdmin } = action?.payload;
      let newState = {
        ...state,
        token,
        user,
        isAdmin,
        isLoggedIn,
      };

      return newState;
    case "SET_ENV":
      state.env = action?.payload;
      return state;

    default:
      return { ...state, ...action.payload };
  }
}
