import { IProductMetaData } from "../interfaces";
import { useFetch } from "./index";
import { DateRange } from "materialui-daterange-picker";

export const useApi = () => {
  const getProducts = useFetch(
    ({ page = 1, limit = 100, country = "mafua", search }) => {
      let url = `/api/products/${country}?page=${page}&limit=${limit}`;

      if (search) {
        url += "&search=" + search;
      }

      return {
        url: url,
        method: "GET",
      };
    }
  );

  const getProductsByUser = useFetch(
    ({ email = "", country = "mafua", status = "approved", date }) => {
      let url = `/api/status/${country}/${email}?status=${status}`;

      if (date && date?.startDate) {
        const { endDate, startDate } = date as DateRange;
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      return {
        url: url,
        method: "GET",
      };
    }
  );

  const setproduct = useFetch((data: IProductMetaData | IProductMetaData[]) => {
    let url = `/api/product-status`;

    return {
      url: url,
      method: "POST",
      data,
    };
  });

  return { getProducts, setproduct, getProductsByUser };
};

export default useApi;
