import React, { lazy, Suspense } from "react";

const PageLoader: React.FC = () => <div />;

const DynamicLoader = (
  path: () => Promise<any>,
  CustomLoader?: React.Component
): React.FC => {
  const AsyncComponent: React.FC = lazy(path);

  return (props) => {
    return (
      <Suspense fallback={CustomLoader ? CustomLoader : <PageLoader />}>
        <AsyncComponent {...props} />
      </Suspense>
    );
  };
};

export default DynamicLoader;
