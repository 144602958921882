import { Box } from "@material-ui/core";
import MainLayout from "./components/MainLayout";
import React from "react";

interface IState {
  error: {} | any;
  errorInfo: {} | any;
}

interface IProps {
  children: React.Component | any;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  state: IState = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;

    if (errorInfo) {
      console.error(error && error.toString());
      console.error(errorInfo.componentStack);
      return (
        <MainLayout>
          <Box
            fontSize="2rem"
            color="primary.main"
            textAlign="center"
            fontWeight="600"
            mt={3}
          >
            Oops! Something went wrong.
          </Box>
          <Box
            fontSize="1rem"
            color="primary.main"
            textAlign="center"
            fontWeight="500"
            mt={2}
          >
            Please try again later.
          </Box>
        </MainLayout>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
