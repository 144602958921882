import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import notify from "./Notification.service";
import Alert from "@material-ui/lab/Alert";
import { Button, Snackbar } from "@material-ui/core";

interface INotifystate {
  show: boolean;
  content: any;
  type: "error" | "success" | "info" | "warning" | undefined;
  onClose: any;
  closeText?: string;
}

export const CustomAlert = withStyles(() => ({
  root: {
    borderRadius: 10,
  },
}))(Alert);

class Notification extends Component<any> {
  unlisten: any;
  constructor(props: any) {
    super(props);
    notify.register(this);
  }

  state: INotifystate = {
    show: false,
    content: null,
    type: "error",
    closeText: "Close",
    onClose: () => {},
  };

  show = (
    content: any,
    type = "error",
    onClose = () => {},
    closeText = "close"
  ) => {
    this.setState({ content, show: true, type, onClose, closeText });
  };

  componentWillUnmount() {
    if (this?.unlisten) this.unlisten();
  }

  hide = () => {
    if (this.state.show && this.unlisten) this.unlisten();
    this.setState({ show: false, content: null });
    if (this.state.onClose) this.state.onClose();
  };

  render() {
    const { show, content, type, closeText } = this.state;

    if (!show) return null;

    return (
      <>
        {Array.isArray(content) ? (
          content.map((message, key) => (
            <CustomAlert
              severity={type}
              key={`notification-${type}-${key}`}
              onClose={this.hide}
            >
              {message}
            </CustomAlert>
          ))
        ) : (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={this.hide}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <CustomAlert
              severity={type}
              onClose={this.hide}
              action={
                <Button color="inherit" size="small" onClick={this.hide}>
                  {closeText}
                </Button>
              }
            >
              {content}
            </CustomAlert>
          </Snackbar>
        )}
      </>
    );
  }
}

export default Notification;
