import { Button, withStyles } from "@material-ui/core";
import Tooltip from "./Tooltip";
export default function ButtonWithTooltip({ title, ...props }: any) {
  if (title) {
    return (
      <Tooltip title={title}>
        <Button {...props} p={0} m={0} />
      </Tooltip>
    );
  }

  return <Button {...props} />;
}

export const AdminButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  label: {
    textTransform: "capitalize",
  },
})(ButtonWithTooltip);

export const SmallButton = withStyles({
  root: {
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 10,
  },
  label: {
    textTransform: "capitalize",
  },
})(ButtonWithTooltip);
