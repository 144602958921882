export const ADMIN_LIST = [
  "ademllo@mafcarrefour.com",
  "kmallubhotla@mafcarrefour.com",
  "svanamala@mafcarrefour.com",
  "sthabti@mafcarrefour.com",
  "ksingh@mafcarrefour.com",
  "edelgado@mafcarrefour.com",
  "pbalakrishnan@mafcarrefour.com",
  "vsubramanian@mafcarrefour.com",
];

export const USER_LIST = [
  { value: "cnegi@mafcarrefour.com", label: "Chetan Negi" },
  { value: "schhibber@mafcarrefour.com", label: "Swati Chhibber" },
  { value: "asuram@mafcarrefour.com", label: "Asha Suram" },
  { value: "grman@mafcarrefour.com", label: "Grandhi Pavan Naga Venkata" },
  { value: "smalhotra@mafcarrefour.com", label: "Shreya Malhotra" },
  { value: "sthabti@mafcarrefour.com", label: "Sabeur Thabti" },
];

export const ALLOWED_USERS = ADMIN_LIST.concat(
  USER_LIST.map((item: any) => item.value)
);

export const REJECT_REASONS = [
  {
    value: "Unclear/Conflicting Product Name",
    label: "Unclear/Conflicting Product Name",
  },
  {
    value: "Incorrect or Missing Brand name",
    label: "Incorrect or Missing Brand name",
  },
  { value: "Title & Image Mismatch", label: "Title & Image Mismatch" },
  {
    value: "Description & Image Mismatch",
    label: "Description & Image Mismatch",
  },
  {
    value: "Description Mismatch With The Product/Title",
    label: "Description Mismatch With The Product/Title",
  },
  {
    value: "Irrelevant or no product description",
    label: "Irrelevant or no product description",
  },
  {
    value: "Redirected Information",
    label: "Redirected Information",
  },
  {
    value: "Abusive Content",
    label: "Abusive Content",
  },
  {
    value: "Image Incorrect - (Sequencing/Ratio/Dimension/DPI)",
    label: "Image Incorrect - (Sequencing/Ratio/Dimension/DPI)",
  },
  {
    value: "Minimum 3 Images Required",
    label: "Minimum 3 Images Required",
  },
  {
    value: "Image Editing Required",
    label: "Image Editing Required",
  },
  {
    value: "Incorrect/Invalid Product ID's(EAN)used",
    label: "Incorrect/Invalid Product ID's(EAN)used",
  },
  {
    value: "Banned Products - Image/Keywords",
    label: "Banned Products - Image/Keywords",
  },
  {
    value: "Other",
    label: "Other",
  },
];
