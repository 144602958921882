import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import productsModeration from "./productsModeration";

const reducerCombined = combineReducers({
  productsModeration: productsModeration,
});

const store = configureStore({
  reducer: reducerCombined,
});

export type RootState = ReturnType<typeof reducerCombined>;
export const reducer = { productsModeration };
export const productsModerationSlice = productsModeration;
export default store;
