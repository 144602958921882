import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import theme from "../../theme";

interface Props extends BoxProps {
  children: React.ReactNode;
  color?: "primary" | "secondary";
}

export default function PanelHeader({ children, color, ...rest }: Props) {
  return (
    <Box
      bgcolor={
        color === "secondary"
          ? theme.palette.secondary.main
          : theme.palette.primary.main
      }
      color="white"
      height="50px"
      p={2}
      {...rest}
    >
      {children}
    </Box>
  );
}
