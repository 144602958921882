import React from "react";
import Container from "@material-ui/core/Container";
import { Box } from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Carrefour from "../../assets/carrefour.png";
import Link from "../Link";
import theme from "../../theme";
import { Flex, Text } from "../index";
import UserAvatar from "../UserAvatar";

export const Nav = () => {
  const classes = useStyles();
  return (
    <>
      <Box height="2px" bgcolor={theme.palette.primary.main}></Box>
      <nav className={classes.root}>
        <Box display="flex">
          <Container maxWidth={"xl"}>
            <Flex flexDirection="row" justifyContent="space-between" px={3}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Link to={"/"}>
                  <Box className={classes.logo} width="200px" height="50px">
                    <img src={Carrefour} alt="Carrefour Marketplace" />
                  </Box>
                </Link>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  className={classes.text}
                >
                  <Text
                    fontWeight="500"
                    mr={1}
                    display={["none", "none", "block"]}
                  >
                    Marketplace Tools
                  </Text>
                </Box>
              </Box>

              <UserAvatar />
            </Flex>
          </Container>
        </Box>
      </nav>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.5),
      background: "white",
    },

    logo: {
      display: "inline-block",
      "& > img": {
        maxWidth: "100%",
        height: "80%",
      },
    },

    text: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  })
);

export default Nav;
