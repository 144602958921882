import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Check, Close } from "@material-ui/icons";
import { ToggleButton } from "@material-ui/lab";
import theme from "../theme";
import CustomTooltip from "./Tooltip";

export default function ToggleBox({
  onChange,
  selected,
  name,
  children,
  title,
  flipped,
}: any) {
  const cx = useStyles();
  let cn = cx.toggleButton;
  if (flipped) cn = cx.toggleButtonRed;

  return (
    <CustomTooltip title={title || ""}>
      <ToggleButton
        value={name}
        selected={selected}
        onChange={onChange}
        className={cn}
      >
        {selected ? <Check /> : <Close />}
        <Typography noWrap style={{ marginLeft: theme.spacing(1) }}>
          {children}
        </Typography>
      </ToggleButton>
    </CustomTooltip>
  );
}

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    "&.Mui-selected": {
      backgroundColor: green[300],
      color: "white",
    },
  },
  toggleButtonRed: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    "&.Mui-selected": {
      backgroundColor: red[300],
      color: "white",
    },
  },
}));
