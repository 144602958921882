import React, { useContext } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Box, Fade, Button, Divider, Menu, MenuItem } from "@material-ui/core";
import {
  SupervisedUserCircle,
  AccountCircle,
  KeyboardArrowDown,
} from "@material-ui/icons";
// local
import theme from "../../theme";

import { If, Text } from "../index";
import { DataContext, IDataState } from "../../context";
import { loginRequest } from "../../Ad";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { useHistory } from "react-router-dom";

export default function UserAvatar() {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = (event: any) => setAnchorEl(event.currentTarget);

  const { isAdmin } = useContext(DataContext) as IDataState;
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const classes = useStyles();

  const handleLogout = () => {
    instance.logout();
  };

  const account = instance.getAllAccounts()
    ? instance.getAllAccounts()[0]
    : ({} as any);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        className={classes.avatarBox}
        onClick={openMenu}
      >
        <Fade in={isAuthenticated}>
          <AuthenticatedTemplate>
            <Box component="span" display="inline-block" fontSize={30}>
              {isAdmin ? (
                <SupervisedUserCircle fontSize={"inherit"} color="secondary" />
              ) : (
                <AccountCircle fontSize={"inherit"} color="primary" />
              )}
            </Box>
            <Box mx={1} flexDirection="column" display={["none", "flex"]}>
              <Text fontSize={10} fontWeight={500}>
                Hi, {account?.name}
              </Text>

              <Text
                fontSize={12}
                color={theme.palette.primary.main}
                fontWeight={500}
              >
                {account?.username}
              </Text>
            </Box>
            <KeyboardArrowDown />
          </AuthenticatedTemplate>
        </Fade>

        <UnauthenticatedTemplate>
          <Button
            color="secondary"
            onClick={() => instance.loginRedirect(loginRequest)}
            size="large"
          >
            login
          </Button>
        </UnauthenticatedTemplate>
      </Box>
      <AuthenticatedTemplate>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          classes={{ paper: classes.menu }}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
          <If test={isAdmin}>
            <Divider />
            <MenuItem onClick={() => history.push("/moderation/admin")}>
              Admin View
            </MenuItem>
          </If>
        </Menu>
      </AuthenticatedTemplate>
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    avatarBox: {
      cursor: "pointer",
      padding: theme.spacing(1),
      "&:hover": {
        background: "#eee",
        transition: "background .4s",
      },
    },
    menu: {
      borderRadius: "8px",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
      width: "186px",
      marginTop: "17px",
      "& .MuiListItem-button": {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 500,
      },
      "& .MuiListItem-button:hover": {
        backgroundColor: "white",
        color: theme.palette.primary.main,
      },
    },
  })
);
