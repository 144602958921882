// inspired from https://medium.com/@jaryd_34198/seamless-api-requests-with-react-hooks-part-2-3ab42ba6ad5c
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { actions } from "../store/productsModeration";

// import notify from "components/Notification/Notification.service";

const INITIAL_STATE = {
  data: null,
  complete: false,
  pending: false,
  error: false,
  success: false,
};

interface IUseFetch {
  state: {
    data: any;
    pending: boolean;
    success: boolean;
    error: any;
    complete: boolean;
  };
  hit: (args: any) => void;
}

export function useFetch(fn: (params: any) => void): IUseFetch {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    ...INITIAL_STATE,
  });
  const [req, setReq] = useState<any>();

  useEffect(() => {
    const call = async () => {
      try {
        if (!req) return;

        setState({
          ...INITIAL_STATE,
          pending: true,
        });

        dispatch(actions.toggleLoading(true));

        const url = req?.url;
        axios({
          ...req,
          url: url,
          withCredentials: false,
          headers: {
            "Content-Type": "application/json",
            ...req?.headers,
          },
        })
          .then((res: any) => {
            setState({
              data: res.data,
              pending: false,
              error: false,
              complete: true,
              success: true,
            });

            setTimeout(() => {
              dispatch(actions.toggleLoading(false));
            }, 3000);
          })
          .catch((error: any) => {
            // req.showNotify !== false &&
            //   notify.show(error?.response?.data?.message || error);
            console.log(error);
            dispatch(actions.toggleLoading(false));
            setState({
              data: null,
              pending: false,
              error: error.response ? error.response.data : error,
              complete: true,
              success: false,
            });
          });
      } catch (e) {
        dispatch(actions.toggleLoading(false));
        console.log(e);
      }
    };

    call();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [req]);

  return { state, hit: (args: any) => setReq(fn(args)) };
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
