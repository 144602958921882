import { styled } from "@material-ui/core/styles";
import Box, { BoxProps } from "@material-ui/core/Box";

const Flex = styled(Box)<BoxProps>({
  display: "flex",
  justifyItems: "center",
  flexWrap: "wrap",
  flexDirection: (props: any) => props.flexDirection || "column",
  width: (props: any) => props.width || "100%",
});
export default Flex;
