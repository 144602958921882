import React from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import { default as MuTooltip } from "@material-ui/core/Tooltip";

export const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 1.5,
    backgroundColor: theme.palette.common.black,
    color: "white",
    padding: 10,
    minWidth: 100,
    textAlign: "center",
    borderRadius: 10,
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(MuTooltip);

const Tooltip = (props: any) => {
  return <CustomTooltip arrow {...props}></CustomTooltip>;
};
export default Tooltip;
