import React from "react";
import Nav from "../Nav";
import { Box, LinearProgress } from "@material-ui/core";
import Notification from "../Notification";

export default function MainLayout({ children, isLoading }: any) {
  if (isLoading) return <LinearProgress />;
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        bgcolor="#fafafa"
        minHeight="100vh"
      >
        <Box flex="1 0 auto">
          <Nav />
          <Notification />
          {children}
        </Box>
      </Box>
    </>
  );
}
