import { createSlice } from "@reduxjs/toolkit";
import { IProduct } from "../interfaces";
// Slice
export const slice = createSlice({
  name: "productModeration",
  initialState: {
    products: [],
    showDescription: true,
    selectedProducts: [],
    isLoading: false,
    showRejectModal: false,
    showAssignModal: false,
    resetAPICall: false,
  },
  reducers: {
    toggleDescription: (state) => {
      state.showDescription = !state.showDescription;
    },
    setProducts: (state, action) => {
      state.products = action.payload.products;
    },
    setSelectedProducts: (state, action) => {
      state.selectedProducts = state.selectedProducts
        .concat(action.payload as any)
        .filter(
          (v: any, i, a) => a.findIndex((t: any) => t._id === v._id) === i
        );
    },
    removeSelectedProducts: (state, action) => {
      state.selectedProducts = state.selectedProducts.filter(
        (item: IProduct) => {
          return item._id !== action.payload;
        }
      );
    },
    resetSelectedProducts: (state) => {
      state.selectedProducts = [];
    },

    toggleLoading: (state, { payload }) => {
      state.isLoading = payload;
    },

    showRejectModal: (state, { payload }) => {
      state.showRejectModal = payload;
    },
    showAssignModal: (state, { payload }) => {
      state.showAssignModal = payload;
    },
    resetAPICall: (state, { payload }) => {
      state.resetAPICall = payload;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
