/* eslint-disable no-extend-native */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { create } from "jss";
import rtl from "jss-rtl";
import "./utils/polyfill";
import { BrowserRouter as RRRouter, Switch, Route } from "react-router-dom";

import theme from "./theme/theme";
import "./index.css";
import MainLayout from "./components/MainLayout";
import "./App.css";
import store from "./store";
import DynamicLoader from "./DynamicLoader";
import ErrorBoundary from "./ErrorBoundary";
import DataProvider from "./AppProvider";
import { ADAuthProvider } from "./Ad";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const Moderation = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Moderation" */
      /* webpackPrefetch: true */
      "./pages/Moderation"
    )
);
const Login = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Login" */
      /* webpackPrefetch: true */
      "./pages/Login"
    )
);

const Orders = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Orders" */
      /* webpackPrefetch: true */
      "./pages/Orders"
    )
);

const Daily = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Orders" */
      /* webpackPrefetch: true */
      "./pages/Daily"
    )
);
const Backfill = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Orders" */
      /* webpackPrefetch: true */
      "./pages/backfill"
    )
);
const Translation = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Orders" */
      /* webpackPrefetch: true */
      "./pages/Translation"
    )
);
const Landing = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Orders" */
      /* webpackPrefetch: true */
      "./pages/Landing"
    )
);

const Router: React.FC = () => {
  return (
    <RRRouter>
      <ErrorBoundary>
        <Switch>
          <MainLayout isLoading={false}>
            {/* <Route component={Moderation} path="/moderation" /> */}
            <AuthenticatedTemplate>
              <Route component={Moderation} path="/moderation/:page?" exact />
              <Route component={Orders} path="/orders-mirkal" exact />
              <Route component={Daily} path="/orders" exact />
              <Route component={Daily} path="/daily" exact />
              <Route component={Backfill} path="/backfillorders" exact />
              <Route component={Translation} path="/translation" exact />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <Login />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Route component={Landing} path="/" exact />
            </AuthenticatedTemplate>
          </MainLayout>
        </Switch>
      </ErrorBoundary>
    </RRRouter>
  );
};

ReactDOM.render(
  <StylesProvider jss={jss}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <ADAuthProvider>
          <DataProvider>
            <Router />
          </DataProvider>
        </ADAuthProvider>
      </Provider>
    </ThemeProvider>
  </StylesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
