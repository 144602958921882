import * as msal from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const msalConfig = {
  auth: {
    clientId: "e6aeab70-83d4-4067-ad89-b0330ef371c9",
    authority:
      "https://login.microsoftonline.com/0df535d9-521f-46ef-b318-d53cc372f469",
    redirectUri: window.location.origin,
  },
} as msal.Configuration;

export async function callMsGraph(accessToken: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch("https://graph.microsoft.com", options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export const loginRequest = {
  scopes: ["User.Read"],
};

export const ADAuthProvider = ({ children }: any) => {
  const msalInstance = new msal.PublicClientApplication(msalConfig);

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};
