import { IProductMetaData } from "./interfaces";
import React from "react";

export interface IDataState {
  email?: any;
  user: { username?: string } | any;
  isAdmin: boolean;
  env: string;
  isLoading: boolean;
  isLoggedIn?: boolean | any;
  token?: string;
  error?: any;
  setState: ({ type, payload }: { type: string; payload?: any }) => void;
  tasks: {
    updateProducts: {
      hit: (products?: any, data?: Partial<IProductMetaData>) => void;
      complete: boolean;
      pending: boolean;
    };
  };
}

export const defaultContext = {
  isLoggedIn: false,
  env: "prod",
  isAdmin: false,
  isLoading: false,
  email: undefined,
  token: undefined,
  user: {},
  setState: () => null,
  tasks: {
    updateProducts: {
      hit: () => null,
      pending: false,
      complete: false,
    },
  },
} as IDataState;

export const DataContext = React.createContext<IDataState>(defaultContext);
